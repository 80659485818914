import * as React from 'react';
import classNames from 'classnames';

const FeedbackCard = (props) => {

    const className = "d-inline-flex text-white "+props.col;

    return (
        <div className={className}>
        <div className="d-flex w-100">
          <div className='border-radius-50px w-100 bg-card m-2 bonus-card pt-5 px-5'>
                <div>
                    <textarea rows={6} className='w-100 d-flex border-radius-50px header border-0'></textarea>
                </div>
                <div className='mt-3 d-flex justify-content-between'>

                    <div>
                        <div className='circle-white'>
                            <img src='/tmp/file.svg'/>
                        </div>
                    </div>
                    <button className='vpartner-button-white'>TÁMOGATOM</button>
                </div>
          </div>
       </div>
      </div>
          
      
    );
   
}
export default FeedbackCard;