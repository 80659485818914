import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './components/App';
import reportWebVitals from './reportWebVitals';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import 'bootstrap/dist/css/bootstrap.min.css';

// redux
import { Provider } from 'react-redux';
import basicStoreConfig from './redux/basicStoreConfig';
import AdminPage from './components/AdminPage';
import IndexPage from './components/IndexPage';
import {
  redirect,
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
  Navigate,
} from "react-router-dom";
import BasicLayout from './components/BaseLayout';
import { Helmet } from 'react-helmet';


// persistor
const myPersistor = persistStore(basicStoreConfig);

const root = ReactDOM.createRoot(
    document.getElementById('root') 
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <BasicLayout><IndexPage/></BasicLayout>
  },
  {
    path: "/admin",
    element: <BasicLayout><AdminPage/></BasicLayout>
  },
]);


root.render(
  
    <Provider store={basicStoreConfig}>
        <PersistGate persistor={myPersistor}>
          <RouterProvider router={router} />
        </PersistGate>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();