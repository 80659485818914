import logo from '../logo.svg';
import './App.css';

import Header from './Header';
import AppContent from './AppContent';
import HeaderComp from './HeaderComp';
import { useEffect , useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { increment, incrementByAmount } from '../counterSlice'
import AdminPage from './AdminPage';

function App() 
{
  const [user, setUser] = useState('ANONYM');


  const count = useSelector((state) => state.counter.value)
  const dispatch = useDispatch()

  return (
    <div className="App">
    asd
    <div>
      <div>
        <button
          aria-label="Increment value"
          onClick={() => dispatch(increment())}
        >
          Increment
        </button>
        <span>{count}</span>
        <button
          aria-label="Decrement value"
          onClick={() => dispatch(incrementByAmount(10))}
        >
          Decrement
        </button>
      </div>
    </div>
    


      <div className="container-fluid">
        <div className="row">
          <div className="col">

            <AdminPage/>

          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
