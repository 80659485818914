import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux'



export default function Buttons(props) {
  
  
  const count = useSelector((state) => state.counter.value)
  
  return (
    <div className="row">

      {count}

      <div className="col-md-12 text-center" style={{ marginTop: '30px' }}>
        <button className="btn btn-primary" style={{ margin: '10px' }} onClick={props.login}>
          Login
        </button>
        <button className="btn btn-dark" style={{ margin: '10px' }} onClick={props.logout}>
          Logout
        </button>
      </div>
    </div>
  );
};
