import { createSlice, createSelector } from '@reduxjs/toolkit'

const initialState = {
    countFromStore: 0
}


const countSlice = createSlice({
    name: 'Count Slice',
    initialState: initialState,
    reducers: {
        increment: state => {
            state.countFromStore = state.countFromStore + 1;
        },
        incrementByAmount: (state, action) => {
            state.countFromStore = state.countFromStore + action.payload; 
        }
    }
});

const selectSelf = (state) => state.shakeAndBake;
export const selectCountFromStore = createSelector(selectSelf, (state)=> state.countFromStore); 


export default countSlice;