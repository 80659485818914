
import '././App.css';

import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import countSlice from '../redux/countSlice';
import userSlice from '../userSlice';
import { Link } from 'react-router-dom';

function BasicLayout({ children }) 
{
  const dispatch = useDispatch();

  const user = useSelector((state) => {
    return state.userRedux.user;
  });

  const logout = () => {
    dispatch(userSlice.actions.logout());
  };

  return (
    <div className="layout">
      <header>

      <div className='w-100 header text-end text-white'>
        <div className='d-flex justify-content-between px-2 py-3'>
              <div className='header-menu'>
                    <img src="/tmp/menu-icon.svg"/>
              </div>
              <div className='header-logo'>
                    <img src="/tmp/logo.svg"/>
              </div>
              <div className='header-social'>
                    <img src="/tmp/telegram.svg"/>
                    <img src="/tmp/discord.svg"/>
              </div>
        </div>

        <div id='loginModal'>

        </div>

        {
          user != null 
          
          ? 
            <>
              <span className='m-2'> {user.username} </span>  
              <button onClick={logout} className='btn-dark btn d-inline-flex m-2'>Logout</button> 
            </>  
          : 
          <></>
        }

      </div>
      </header>
      <main>{children}</main>
      <footer className='w-100 bg-success text-center text-white py-4'>Footer content goes here</footer>
    </div>
  );
}
export default BasicLayout;
