import { configureStore, combineReducers } from '@reduxjs/toolkit';

// persistor
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

// slices
import countSlice from './countSlice'; 
import inputSlice from './inputSlice';
import userSlice from '../userSlice';


const persistConfigOne = {
    key: 'youtubeKey',
    storage
};

const myCombinedReducer = combineReducers({
    shakeAndBake: countSlice.reducer,
    userRedux: userSlice.reducer,
    inputThingy: inputSlice.reducer
});

const localStorageReducer = persistReducer(persistConfigOne, myCombinedReducer); 


const basicStoreConfig = configureStore({
    reducer: localStorageReducer
});

export default basicStoreConfig; 