import logo from '../logo.svg';
import '././App.css';
import Header from './Header';
import AppContent from './AppContent';
import HeaderComp from './HeaderComp';
import { request, setAuthHeader,getUser, axiosLogout } from '../helpers/axios_helper';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import countSlice from '../redux/countSlice';
import userSlice from '../userSlice';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import PromoterCard from './PromoterCard';
import BonusCard from './BonusCard';
import { Helmet } from 'react-helmet';
import GiveawayCard from './GiveawayCard';
import SocialCard from './SocialCard';
import RaceCard from './RaceCard';
import FeedbackCard from './FeedbackCard';


const IndexPage = () => {

  const user = useSelector((state) => {
    return state.userRedux.user;
  });

  const count = useSelector((state) => {
    return state.shakeAndBake.countFromStore;
  });

  const logout = () => {
    dispatch(userSlice.actions.logout());
  };

  const error401Check = (error) => {

    if(error.response.status==401)
    {
      dispatch(userSlice.actions.logout());
      axiosLogout();
      alert('SHOW LOGIN');
    }

  };
  
  const fetchPromoters = (e) => {
    request(
      "GET",
      "/promoter",
      ).then(
      (response) => {
        setPromoterList(response.data);
      }).catch(
      (error) => {
        error401Check(error);
      }
  )}

  const sendMail = (e) => {
    request(
      "GET",
      "/verify/email-send",
      ).then(
      (response) => {
        alert('send');
      }).catch(
      (error) => {
        error401Check(error);
      }
  )}

  const [promoterList,setPromoterList] = useState([]);
  
  useEffect( () => {

    fetchPromoters();

  },[]);

  const submitVote = (promoterId) => {
    request(
      "POST",
      "/promoter/vote/"+promoterId,
      {
        
      }).then(
      (response) => {
          fetchPromoters();
          
          dispatch(userSlice.actions.login({
            username : user.username,
            token: response.data.token,
            voted: promoterId,
            roles : user.roles
          }));
      }).catch(
      (error) => {
        error401Check(error);
      }
      
  );
    

  };


  const [email,setEmail] = useState("");
  const [phoneNubmer,setPhoneNubmer] = useState("");
  const [phoneNumberCode,setPhoneNumberCode] = useState("");
  const [username,setUsername] = useState("");
  const [password,setPassword] = useState("");
  const [firstName,setFirstName] = useState("");
  const [lastName,setLastName] = useState("");
  const [verificationCode,setVerificationCode] = useState("");
  
  const navigate = useNavigate();
  const dispatch = useDispatch();



  const handleVerification = (e) => {

                e.preventDefault();

                request(
                  "PUT",
                  "/verify/email-by-code/"+verificationCode,
                  {}).then(
                  (response) => {
                      alert('emc ok!')
                  }).catch(
                  (error) => {
                    alert('ERORR EMC!')
                  }
              );
  }

  const handleSmSVerification = (e) => {

    e.preventDefault();

    request(
      "PUT",
      "/verify/sms-by-code/"+phoneNumberCode,
      {}).then(
      (response) => {
          alert('Sms ok!')
      }).catch(
      (error) => {
        alert('ERORR Sms!')
      }
  );
}

const handleSendSms = (e) => {
  
  e.preventDefault();

  request(
    "POST",
    "/verify/sms/"+phoneNubmer,
    {}).then(
    (response) => {
        alert('SMS S E N T !')
    }).catch(
    (error) => {
        setAuthHeader(null);
    }
);

}

   const handleReg = (e) => {

      e.preventDefault();
      request(
          "POST",
          "/register",
          {
              firstName: firstName,
              lastName: lastName,
              username: username,
              password: password,
              email: email
          }).then(
          (response) => {

            alert('Success login! - L o g i n');
            document.getElementById('regForm').reset();
            setAuthHeader(response.data.token);
              
          }).catch(
          (error) => 
          {
              if(error.response.status==400)
              {
                alert(error.response.data.message);
              }
              setAuthHeader(null);
          }
      );
 
  }

  const handleSubmit = (e) => {

    e.preventDefault();

    request(
        "POST",
        "/login",
        {
            login: username,
            password: password
        }).then(
        (response) => {
            setAuthHeader(response.data.token);

            let user = getUser(response.data.token);
            
            dispatch(userSlice.actions.login({
              username : username,
              token: response.data.token,
              voted: user.voted,
              roles : user.roles,
              smsVerified: user.smsVerified,
              emailVerified: user.emailVerified
            }));

            if(locationState!=null)
            {
              const { redirectTo } = locationState;

              navigate(`${redirectTo.pathname}${redirectTo.search}`);
            }

            

        }).catch(
        (error) => {
            setAuthHeader(null);
        }
    );
  }

  const { state: locationState } = useLocation();

  return (

     
     //user == null ?  <Navigate to={"/"}/> :


        <div className='application'>
            <Helmet>
                      <title>Meta Tags — Preview, Edit and Generate</title>
                      <meta name="title" content="Meta Tags — Preview, Edit and Generate" />
                      <meta name="description" content="With Meta Tags you can edit and experiment with your content then preview how your webpage will look on Google, Facebook, Twitter and more!" />

                      <meta property="og:type" content="website" />
                      <meta property="og:url" content="frontend.hu4-clashbonus.com" />
                      <meta property="og:title" content="Meta Tags — Preview, Edit and Generate" />
                      <meta property="og:description" content="With Meta Tags you can edit and experiment with your content then preview how your webpage will look on Google, Facebook, Twitter and more!" />
                      <meta property="og:image" content="https://metatags.io/images/meta-tags.png" />

                      <meta property="twitter:card" content="summary_large_image" />
                      <meta property="twitter:url" content="frontend.hu4-clashbonus.com" />
                      <meta property="twitter:title" content="Meta Tags — Preview, Edit and Generate" />
                      <meta property="twitter:description" content="With Meta Tags you can edit and experiment with your content then preview how your webpage will look on Google, Facebook, Twitter and more!" />
                      <meta property="twitter:image" content="https://metatags.io/images/meta-tags.png" />
              
            </Helmet>
            <div className='layout'>

              <div className='cover-image cover-div'>
                <span className='cover-text-type'>Ide <span className='text-purple'>valami meggyőző</span> szöveg kell, hogy megfogjuk őket! (Slider szekció)</span>
              </div>
              <div>
                      <div className='row text-center pb-3'>
                          <span className='partners-title text-uppercase text-white my-3'>Partnereink</span>
                            <div className='d-flex justify-content-center'>
                                  {
                                      promoterList.map((promoter) => (
                                        <a className='text-decoration-none partner-list text-white mx-3 ' href={'#'+promoter.name}>{promoter.name}</a>
                                      ))
                                  }
                            </div>                      
                      </div>
                      <div className='row px-5'>
                            {
                                        promoterList.map((promoter) => (
                                          <PromoterCard promoter={promoter} user={user} submitVote={submitVote}/>
                                      ))
                            }
                      </div>
              </div>

              <div className='row pb-3 px-5'>
                   <span className='text-start partners-title text-uppercase text-white m-3'>felerősített bónuszok</span>

                   <BonusCard imgSrc="/tmp/vegas_bonus1.png"/>
                   <BonusCard imgSrc="/tmp/vegas_bonus2.png"/>
                   <BonusCard imgSrc="/tmp/vegas_bonus3.png"/>
              </div>

              <div className='row pb-3 px-5'>
                   <span className='text-start partners-title text-uppercase text-white m-3'>Sorsolások</span>

                    <GiveawayCard imgSrc="/tmp/vegas_ruffle1.png" col={"col-8"}/>
                    <div className='col-4'>
                         <SocialCard/>
                         <SocialCard/>
                    </div>
              </div>

              <div className='row pb-3 px-5'>
                   <span className='text-start partners-title text-uppercase text-white m-3'>exkluzív versenyek</span>

                  <RaceCard imgSrc="/tmp/vegas_banner1.png"/>
                  <RaceCard imgSrc="/tmp/vegas_banner2.png"/>
                  <RaceCard imgSrc="/tmp/vegas_banner3.png"/>
              </div>

              <div className='row pb-3 px-5'>
                   <span className='text-start partners-title text-uppercase text-white m-3'>itt találsz meg minket</span>

                  <SocialCard col={"col-4"}/>
                  <SocialCard col={"col-4"}/>
                  <SocialCard col={"col-4"}/>

                  <SocialCard col={"col-4"}/>
                  <SocialCard col={"col-4"}/>
                  <SocialCard col={"col-4"}/>
                 
              </div>

              <div className='row pb-3 px-5'>
                   <span className='text-start partners-title text-uppercase text-white m-3'>Visszajelzés</span>

                  <GiveawayCard imgSrc="/tmp/vegas_support1.png" col={"col-6"}/>
                  <FeedbackCard col={"col-6"}/>
                 
              </div>

              <div className="mt-3">

                {user != null ? 
                
                <div>

                  <div className='text-center'>
                      <BonusCard/> <BonusCard/> <BonusCard/>
                  </div>
              
                  <div className='d-inline-flex div'>
                            {
                                        promoterList.map((promoter) => (
                                        <>
                                          <PromoterCard promoter={promoter} user={user} submitVote={submitVote}/>
                                          <PromoterCard promoter={promoter} user={user} submitVote={submitVote}/>
                                          </>
                                      ))
                            }
                  </div>

                  <div className='m-2'>SMS: {user.smsVerified == true ? 'VERIFIED' : 'NOT VERIFIED'}</div>
                  <div className='m-2'> EMAIL: {user.emailVerified == true ? 'VERIFIED' : 'NOT VERIFIED'}</div>

                  


                  <button className='btn btn-success m-2' onClick={() => sendMail()}>Send verification email to user</button>
                  <form className='bg-danger p-2' id='mailVerification' onSubmit={ (e) => handleVerification(e)}>
                      <h2>Email verification</h2>
                      <div className="bg-danger">
                        <input type="login" id="emailCode" name= "emailCode" className="d-block" onChange={(e) => setVerificationCode(e.target.value)}/>
                        <label className="form-label" htmlFor="emailCode">Email Verify Code</label>
                      </div>
                      <button type='submit' className='btn btn-sm btn-warning'>Check code</button>
                  </form>

                  <form className='bg-danger p-2' id='smsVerification' onSubmit={ (e) => handleSendSms(e)}>
                      <h2>Send sms code</h2>
                      <div className="bg-danger">
                        <input type="text" id="phoneNumber" name= "phoneNumber" className="d-block" onChange={(e) => setPhoneNubmer(e.target.value)}/>
                        <label className="form-label" htmlFor="phoneNumber">Phone Number</label>
                      </div>
                      <button type='submit' className='btn btn-sm btn-warning'>Send OTP to phoneNumber</button>
                  </form>

                  <form className='bg-danger p-2' id='smsVerification' onSubmit={ (e) => handleSmSVerification(e)}>
                      <h2>Sms verification</h2>
                      <div className="bg-danger">
                        <input type="login" id="smsCode" name= "smsCode" className="d-block" onChange={(e) => setPhoneNumberCode(e.target.value)}/>
                        <label className="form-label" htmlFor="smsCode">Phone Number Verify Code</label>
                      </div>
                      <button type='submit' className='btn btn-sm btn-warning'>Check sms code</button>
                  </form>
                </div>
                
                : 
                
                <div className="container-fluid">
                  <div className="row">
                    <div className="col">
            

                    <form onSubmit={ (e) => handleSubmit(e)}>

                    <div className="form-outline mb-4">
                      <input type="login" id="loginName" name= "login" className="form-control" onChange={(e) => setUsername(e.target.value)}/>
                      <label className="form-label" htmlFor="loginName">Username</label>
                    </div>

                    <div className="form-outline mb-4">
                      <input type="password" id="loginPassword" name="password" className="form-control" onChange={(e) => setPassword(e.target.value)}/>
                      <label className="form-label" htmlFor="loginPassword">Password</label>
                    </div>

                    <button type="submit" className="btn btn-primary btn-block mb-4">Sign in</button>

                    </form>


                    <form id='regForm' onSubmit={(e) => handleReg(e)}>

                        <div className="form-outline mb-4">
                          <input type="text" id="firstName" name="firstName" className="form-control" onChange={(e) => setFirstName(e.target.value)}/>
                          <label className="form-label" htmlFor="firstName">First name</label>
                        </div>

                        <div className="form-outline mb-4">
                          <input type="text" id="lastName" name="lastName" className="form-control" onChange={(e) => setLastName(e.target.value)}/>
                          <label className="form-label" htmlFor="lastName">Last name</label>
                        </div>

                        <div className="form-outline mb-4">
                          <input type="text" id="login" name="login" className="form-control" onChange={(e) => setUsername(e.target.value)}/>
                          <label className="form-label" htmlFor="login">Username</label>
                        </div>

                        <div className="form-outline mb-4">
                          <input type="text" id="email" name="email" className="form-control" onChange={(e) => setEmail(e.target.value)}/>
                          <label className="form-label" htmlFor="email">Email address</label>
                        </div>

                        <div className="form-outline mb-4">
                          <input type="password" id="registerPassword" name="password" className="form-control" onChange={(e) => setPassword(e.target.value)}/>
                          <label className="form-label" htmlFor="registerPassword">Password</label>
                        </div>

                        <button type="submit" className="btn btn-primary btn-block mb-3">Sign up</button> 
                        </form>

                  </div>


            
                    </div>

            

                  
                </div>
                
                }


              </div>

             
            </div>
        </div>

    
  );
}

export default IndexPage;
