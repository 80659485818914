import * as React from 'react';
import classNames from 'classnames';

const SocialCard = (props) => {

    let divClassName = "d-inline-flex text-white "+props.col;

    return (
                <div className={divClassName}>
                <div className="">
                <div className='border-radius-50px d-inline-flex bg-card m-2 bonus-card'>
                <div className="">
                    <img className="border-radius-50px h-100 social-pre-image" src="/tmp/vegas_discord1.png"/>
                </div>
                <div className="d-flex flex-column align-self-center gap-1 px-3 py-2 w-100 ">
                    <span className='bonus-card-title  text-uppercase text-center'>Sportfogadás</span>
                    <span className='text-center bonus-card-description text-uppercase py-0'>csatlakozz és ne maradj le
                    a sorsolásokról!</span>
                    
                </div>
                </div>
            </div>
            </div>

    );
   
}
export default SocialCard;