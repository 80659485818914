import * as React from 'react';
import classNames from 'classnames';

const BonusCard = (props) => {

    return (

            <div className="col-4 d-inline-flex text-white">
              <div className="">
                <div className='border-radius-50px d-inline-flex bg-card m-1 bonus-card'>
                <div className="">
                    <img className="border-radius-50px h-100 bonus-pre-image" src={props.imgSrc}/>
                </div>
                <div className="d-flex flex-column align-self-center gap-3 px-4  w-100">
                    <div className='d-flex flex-column'>
                              <span className='bonus-card-title text-start text-uppercase'>Sportfogadás bónusz</span><span className='bonus-card-description text-uppercase py-2'>ide valami leírás a bónuszok-ról Lorem Ipsum&nbsp;is simply dummy text of the printing and typesetting industry.</span>
                               <button className="vpartner-button-white width-content align-self-center">TÁMOGATOM</button>
                    </div>

                </div>
                </div>
             </div>
            </div>
                
      
    );
   
}
export default BonusCard;