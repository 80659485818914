import logo from '../logo.svg';
import '././App.css';
import Header from './Header';
import AppContent from './AppContent';
import HeaderComp from './HeaderComp';
import { request, setAuthHeader,getRoles } from '../helpers/axios_helper';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import countSlice from '../redux/countSlice';
import userSlice from '../userSlice';
import { Navigate, useLocation } from 'react-router-dom';

const AdminPage = () => {

  const user = useSelector((state) => {
    return state.userRedux.user;
  });

  const count = useSelector((state) => {
    return state.shakeAndBake.countFromStore;
  });

  const logout = () => {
    dispatch(userSlice.actions.logout());
  };

  const [username,setUsername] = useState("");
  const [password,setPassword] = useState("");
  const dispatch = useDispatch();

  const location = useLocation();
  const hasAdminRole = () => {

    if(user !=null)
    {
       return user.roles.indexOf("ADMIN") > -1;
    }
    return false;
  }

  return (

     user == null && !hasAdminRole() ?  <Navigate to={"/"} state={{redirectTo:location}}/> :

    <div className='layout p-5 text-center text-danger'>

      <header>
        Admin role 
      </header>
   
    </div>
  );
}

export default AdminPage;
