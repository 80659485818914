import axios from 'axios';
import { increment, incrementByAmount } from '../counterSlice'
import { useSelector, useDispatch } from 'react-redux'
import { jwtDecode } from "jwt-decode";


export const getAuthToken = () => {
    return window.localStorage.getItem('auth_token');
};

export const getUser = (token) => {

  console.log(jwtDecode(token));

  return jwtDecode(token)

};

export const setAuthHeader = (token) => {
    if (token !== null) {
      window.localStorage.setItem("auth_token", token);
    } else {
      window.localStorage.removeItem("auth_token");
    }
};

export const axiosLogout = () => {
  window.localStorage.removeItem("auth_token");
};

axios.defaults.baseURL = '';
axios.defaults.headers.post['Content-Type'] = 'application/json';

export const request = (method, url, data) => {

    let headers = {};
    if (getAuthToken() !== null && getAuthToken() !== "null") {
        headers = {'Authorization': `Bearer ${getAuthToken()}`};
    }

    return axios({
        method: method,
        url: process.env.REACT_APP_BACKEND_URL+url,
        headers: headers,
        data: data});
};