import * as React from 'react';
import classNames from 'classnames';

const RaceCard = (props) => {

    return (

            <div className="col-4 d-inline-flex text-white">
              <div className="">
                <div className='border-radius-50px bg-card m-2 bonus-card text-center flex-column d-flex gap-4 pb-4'>
                    <img className="border-radius-50px w-100 bonus-pre-image" src={props.imgSrc}/>
                    <span className='bonus-card-title text-uppercase mx-3'>Sportfogadás bónusz</span>
                    <span className='bonus-card-description text-uppercase mx-3'>ide valami leírás a bónuszok-ról Lorem Ipsum&nbsp;is simply dummy text of the printing and typesetting industry.</span>
                    <button className="vpartner-button-white width-content align-self-center">TÁMOGATOM</button>
                </div>
             </div>
            </div>
                
      
    );
   
}
export default RaceCard;