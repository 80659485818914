import * as React from 'react';
import classNames from 'classnames';

const GiveawayCard = (props) => {

    const className = "d-inline-flex text-white "+props.col;

    return (

            <div className={className}>
              <div className="">
                <div className='border-radius-50px d-inline-flex bg-card m-2 bonus-card'>
                <div className="image-holder">
                    <img className="border-radius-50px giveaway-pre-image" src={props.imgSrc}/>
                </div>
                <div className="d-flex flex-column align-self-center gap-3 px-3 py-4 w-100">
                    <span className='bonus-card-title text-start text-uppercase'>Sorsolások</span><span className='bonus-card-description text-uppercase py-2'>ide valami leírás a bónuszok-ról Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
                </div>
                </div>
             </div>
            </div>
                
      
    );
   
}
export default GiveawayCard;