import logo from '../logo.svg';
import './App.css';

import Header from './Header';
import AppContent from './AppContent';


function HeaderComp(props) 
{
  return (
    <div>
      {
        props == 'ANONYM' ? 'ANONYM' : 'LOGGED'
      }
    </div>
  );
}

export default HeaderComp;
