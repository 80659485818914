import { createSlice, createSelector } from '@reduxjs/toolkit'


const initialState = {
  user: null
}

export const userSlice = createSlice({
  name: "user",
  initialState: {
    initialState
  },
  reducers: {
    logout: (state) => {
      state.user = null;
    },
    login: (state, action) => {
      state.user = action.payload
    },
  },
})

const selectSelf = (state) => state.userRedux;
export const selectUserFromStore = createSelector(selectSelf, (state)=> state.user); 


export default userSlice;
