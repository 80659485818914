import * as React from 'react';
import classNames from 'classnames';

const PromoterCard = (props) => {

    const promoter = props.promoter;
    
    let votedFor = "YYY";

    if(props.user!=null && props.user.voted !=null)
    {
        votedFor = props.user.voted;
    }

    return (

        <div className='d-inline-block text-white col-12 col-xl-6'>

            <div id={promoter.name} className='d-flex'>
                <div className=''>
                       <img className='avatar-image m-2' src={promoter.image_src}/>
                </div>
                <div className='align-self-center mx-3'>
                    <div className=''>
                            <span className='promoter-name'>{promoter.name}</span>  {promoter.id == votedFor ? '✔' : ''}  <span className='mx-3 supporter-number gap-1'>Támogatók száma:  <span className='text-purple'>{promoter.supportCount}</span></span>
                    </div>
                        
                    <div className='py-2 promoter-description text-uppercase'>{promoter.description}</div>
                                <div className=' gap-1 d-flex align-items-center'>
                                    <a href={promoter.instagram} target='_blank' className=''>
                                        <img src='/tmp/instagram.svg'/>
                                    </a>
                                    <a href={promoter.facebook} target='_blank' className=''>
                                        <img src='/tmp/facebook.svg'/>
                                    </a>
                                    <a href={promoter.twitch} target='_blank' className=''>
                                        <img src='/tmp/twitch.svg'/>
                                    </a>
                                    <a href={promoter.youtube} target='_blank' className=''>
                                        <img src='/tmp/youtube.svg'/>
                                    </a>
                                    <button className='vpartner-button-white' onClick={() =>props.submitVote(promoter.id)} disabled={votedFor!="YYY"}>TÁMOGATOM</button>
                    </div>
                </div>
            </div>

        </div>
    );
   
}
export default PromoterCard;